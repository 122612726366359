@import '../../styles/marketplaceDefaults.css';

.wrapper {
  /* @apply --marketplacePaperBackground;

  background-color: var(--marketplaceColor); */
  background-color: transparent;
  height: auto;
  position: relative;
  border-radius: 11px;
  z-index: 2;
  margin-top: 70px;
  @media (--viewportMedium) {
    /* margin-top: 100px; */
    /* flex-direction: row; */
    /* padding-bottom: 0px; */
    /* margin-left: 100px; */
    /* width: calc(100vw - 200px); */

    /* border-radius: 11px; */
    /* height: auto; */
    transition: 0.5s;
    /* margin-top: 5vh; */
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  text-align: center;
  /* padding-bottom: 50px; */
  transition: 0.2s;
  opacity: 1;
  margin: 50px auto;
  width: 90vw;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 25px auto;
    align-items: flex-start;
    /* padding-bottom: 20px; */
  }

  @media (--viewportMLarge) {
    margin-top: 4vh;
  }
}

.item {
  /* min-height: 30vh; */
  margin: 18px auto;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    /* max-width: 400px; */

    width: 30%;
  }
}

.iconWrapper {
  /* height: 15vh; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  width: 110px;
  height: 110px;
}

.iconWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item_Header {
  color: var(--successColor);
  font-size: 26px;
  line-height: 34px;
  /* min-height: 6vh; */
  margin-top: 0px;

  /* @media (--viewportMedium) {
    font-size: 34px;
    line-height: 44px;
  } */

  @media (--viewportMLarge) {
    font-size: 34px;
    line-height: 44px;
  }
}

.item_Subtitle {
  text-align: center;
  color: var(--successColor);
  @apply --marketplaceMediumTextStyles;
  margin-top: 0;

  @media (--viewportMedium) {
    /* min-height: 17vh; */
  }
}

/* .lineImage{

  width: 130%;
   margin-left: -65%;
   margin-top: 160%;
transform: rotate(90deg);
position: absolute;
   @media (--viewportMedium) {
     position: relative;
    margin-left: 50%;
    margin-top: -30px;
    transform: rotate(0deg);
    width: calc(200% + 200px);
  }

} */

.lineImage {
  width: 110%;
  margin-left: -45vw;
  margin-top: 10vh;
  transform: rotate(90deg);
  position: absolute;
  @media (--viewportMedium) {
    margin-left: -2px;
    margin-top: 110px;
    transform: rotate(0deg);
    width: calc(66% + 20px);
  }

  @media (--viewportMLarge) {
    margin-top: 130px;
  }
}
