@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.inputRoot {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);
  z-index: 9999999 !important;
  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    background-color: transparent;
    display: block;
  }


  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    left: unset !important;
    box-shadow: 0 6px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: solid;
    background-color: white;
    margin-bottom: 1em;
    border-radius: 0px 0px 6px 6px !important;
    border: 1px solid black;
    margin-top: -8px;
    width: 100%;
    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 48px !important;
      left: 0px !important;
      width: 100%;
      min-height: calc(100vh - 252px);
      min-height: auto;
    }
  }

  & :global(.DateInput_input) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px 4px;
    margin: 0;
    border-radius: 5px 5px 5px 5px;
    background-color: white;
    outline: none;

    transition: all 0.1s ease-in-out;

    &:focus {
      border-radius: 5px 5px 5px 5px;
      /*    border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
      transition: all ease-in-out 100ms;
    }

    @media (--viewportMedium) {
      line-height: 32px;
      padding: 7px 16px;
    }
  }
  & :global(.DateInput_input:hover) {
    border-color: #eb7242 !important;
    border-radius: 5px;
    border-width: 2px !important;
    border: solid #eb7242 !important;
  }

  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
    border-radius: 6px;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100% !important;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorDark);
    border: 0;
    filter: brightness(0%);
    -webkit-filter: brightness(0%);
  }

  & :global(.CalendarMonth) {
    width: 100% !important;
    height: 100% !important;
  }
  & :global(.CalendarMonthGrid) {
    width: 100% !important;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorDark);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: var(--fontWeightRegular);
  }

  & :global(.DayPicker__withBorder) {
    border-radius: 0;
    width: 99% !important;
    display: flex;
    justify-content: center;
  }

  & :global(.CalendarMonth_caption) {
    font-family: Noto Sans JP;
    font-weight: 300;
    font-size: 21px;
    line-height: 24px;
    color: var(--successColor);
    margin: 1px 0 14px;
    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;

    color: var(--matterColorDark);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 34px;
    color: var(--successColor);
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: rgb(231, 229, 229);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_start) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: rgb(231, 229, 229);
    border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 4);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 4);
    color: var(--marketplaceColorSecond);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered_start) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background-color: transparent;
  }
  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: rgb(231, 229, 229);
    border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 4);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 4);
    color: var(--marketplaceColorSecond);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover) {
    cursor: pointer;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--matterColorNegative);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--matterColorNegative);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput_input__disabled) {
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.DateRangePickerInput) {
    /*     width: calc(100vw - 48px); */
    margin: 0 24px 3px 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
    @media screen and (max-width: 750px) {
      width: 100%;
      margin-left: 0px;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: calc(50% - 1.5px);
    background: none;

    @media (--viewportMedium) {
      width: calc(50% - 1.5px);
    }
  }

  & :global(.DateInput:first-of-type) {
    margin-right: 12px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }
}

.arrowIcon {
  stroke: black !important;
  fill: black !important;
}

.inputRoot.experienceType :global(.DateInput:last-of-type) {
  pointer-events: none;
}
