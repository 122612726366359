@import '../../../styles/customMediaQueries.css';
@import '../../../styles/marketplaceDefaults.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;
  --TopbarSearchForm_mobilePredictionTop: calc(
    var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
      var(--TopbarSearchForm_bottomBorder)
  );
}

.mobileInputRoot {
  width: 100%;
  background-color: var(--matterColorLight);
}

.landingPageMobileInput {
  width: 270px;
  height: 59px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  background-color: var(--matterColorLight);
}

.desktopInputRoot {
  height: var(--searchBarHeightLanding);
  background-color: var(--matterColorLight);
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  color: var(--matterColor);
  z-index: 99;
}

.mobileIcon {
  margin-left: 14px;
  margin-right: 6px;
  padding: 11px 0 17px 0;
  border-radius: 5px;
  /* Borders */
  /* border-bottom-width: var(--borderRadiusMobileSearch); */

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;
  background-color: var(--matterColorLight);
  border-radius: 5px;

  /* Layout */
  margin: 0 4px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.landingPageInput {
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  min-width: 24px;
  width: 44px;
  background-color: transparent;
}

.landingPageIcon {
  height: var(--searchBarHeightLanding);
  border: none;
  padding-top: 3px;
  margin-left: 24px;
  border-radius: 5px;
  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.desktopInput {
  width: 100%;
  /* Font */
  @apply --marketplaceH4FontStyles;
  /* height: var(--topbarHeightDesktop); */
  line-height: unset;
  border: 1px solid black;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

@media screen and (max-width: 576px) {
  .desktopInput {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
/*     background-color: red; */
    width: 360px !important;
  }
}
@media screen and (max-width: 420px) {
  .desktopInput {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
/*     background-color: blue; */
    width: 260px !important;
  }
}
/* @media screen and (max-width: 420px) {
  .desktopInputRoot .desktopInput {
    border-radius: 0;
    background-color: blue;
    width: 200px !important;
  }
} */
/*
@media screen and (min-width: 577px) and (max-width: 992px) {
  .desktopInput {
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
} */

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  /* min-height: calc(100vh - var(--TopbarSearchForm_mobilePredictionTop)); */
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  /* margin-bottom: 100px; */
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 30px;
  left: -10px;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  z-index: 99;
  @media screen and (max-width: 460px) {
    top: 10px;
    left: -20px;
  }

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  /* max-width: 434px; */
}

/* Top bar search styles */

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  align-items: center;
  height: 35px;
  width: 400px;
  @media screen and (max-width: 360px) {
    width: 180px;
  }
  @media screen and (max-width: 940px) {
    width: 250px;
    margin-left: -3px;
  }
  @media screen and (max-width: 1100px) {
    width: 200px;
  }
  /*   margin-top: 12px; */
}

.searchInput:hover,
.searchInput:focus {
  border: 1px solid black;
  border-radius: 6px;
}

.searchInput {
  padding-left: 40px;
  padding-right: 30px;
  position: relative;
  z-index: 0;
  border: 1px solid black;
  border-radius: 6px;
  width: 400px;
  height: 50px;
  @media screen and (max-width: 1100px) {
    width: 250px;
  }
  @media screen and (max-width: 600px) {
    translate: 10px 0;
  }
  @media screen and (max-width: 360px) {
    width: 200px;
    padding-right: 30px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  top: 36px !important;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  background-color: #1c7881;
  overflow: hidden;
}
