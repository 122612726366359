.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #d3d3d3;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke:  #4a4a4a;
}

.noImageText {
  font-weight:  700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  margin-top: 10px;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 6px;
  }
  color: #4a4a4a;
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
