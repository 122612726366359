.input {
  display: flex;
  flex-direction: column;
  width: 220px;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 10px;
  transition: 1s;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 550px) {
    min-width: 109vw;
    min-height: 100vh;
    border-radius: 0px;
    z-index: 99999999;
    padding-left: 50px;
    margin-left: 5px;
    justify-content: start;
  }
}

.value {
  font-size: 15px;
  background-color: transparent;
  border: none;
  padding: 10px;
  color: black;
  display: flex;
  position: relative;
  gap: 5px;
  cursor: pointer;
  border-radius: 10px;
  transition: 500ms;
  box-sizing: border-box;
  z-index: 9999999;
  @media screen and (max-width: 550px) {
    font-size: 25px;
    padding-top: 20px;
    margin-top: 10px;
    gap: 15px;
    text-align: center;
    align-items: center;
  }
}

.value:not(:active):hover,
.value:focus {
  display: flex;
  box-sizing: border-box;
  border: 2px solid #1c7881;
  color: black;
}

.value:focus,
.value:active {
  background-color: white;
  outline: none;
  margin-left: 17px;
}

.value::before {
  content: '';
  position: absolute;
  top: 5px;
  left: -15px;
  width: 5px;
  height: 80%;
  background-color: #1c7881;
  border-radius: 5px;
  opacity: 0;
  transition: 1s;
}

.value:focus::before,
.value:active::before {
  opacity: 1;
}

.value svg {
  width: 20px;
}

.input:hover > :not(.value:hover) {
  @media screen and (min-width: 550px) {
    transition: 100ms;
    filter: blur(0px);
    transform: scale(0.95, 0.95);
  }
}

.menuContainer {
  z-index: 999999999;
  position: absolute;
  right: -10px;
  top: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  @media screen and (max-width: 550px) {
    width: 101vw;
    height: screen;
    display: block;
    right: 10px;
  }
  @media screen and (max-width: 300px) {
    width: 101vw;
    height: screen;
    display: block;
    right: 40px;
  }
}

.icon {
  width: 30px;
  padding: 0 3px;
  color: #1c7881;
  @media screen and (max-width: 550px) {
    width: 50px;
  }
}

.logoutIcon {
  padding-left: 8px;
  padding-right: 1px;
  width: 29px;
  @media screen and (max-width: 550px) {
    width: 44px;
  }
}

.questionIcon{
  height: 25px;
  padding: 0 3px;
  @media screen and (max-width: 550px) {
    height: 41px;
  }
}

.questionIconClass {
  color: #1c7881 !important;
  z-index: 999999999;
}

.hostMenuItem {
  display: none;
  color: #1c7881 !important;
  @media screen and (max-width: 550px) {
    display: flex;
  }
}

.languageSwitcherMobile {
  margin-left: -8px;
  margin-bottom: 12px;
  @media screen and (min-width: 550px) {
    display: none;
  }
}
