.starContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.starContainer svg {
  margin-right: 0.5em;
  margin-bottom: 0;
  position: relative;
  top: 3px;
}
.starContainer p {
  margin-right: 0.3em;
  font-size: 0.8rem;
}
.starContainer span {
  margin-right: 0.3em;
}
.reviewsHeading {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color: var(--successColor);
  padding-bottom: 0.5em;
}
.reviewsHeading:hover {
  transform: translateY(-2px);
}

.reviewsHeading:active {
  transform: translateY(1px);
}
.reviewsHeadingUnclickable {
  color: lightgrey;
}

.root {
  position: relative;
  width: 23px;
  height: 23px;
}

.starPath {
  stroke: #1c7881;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.starPathUnclickable {
  stroke: #d3d3d3;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

/*specific classes for rerendering in other parts of the listing page*/

.starReviews {
  justify-content: flex-start;
  color: var(--successColor);
  /*  font-family: Noto Sans JP; */
  font-weight: 600;
  margin-bottom: 8px;
}

.starReviews svg {
  margin-right: 0.2em;
}
.starReviews p {
  font-size: 1.2rem;
  margin-right: 0.2em;
  color: var(--successColor) !important;
  font-family: Noto Sans JP;
}
.starReviews span {
  margin-right: 0.2em;
}

.starReviews > .reviewsHeading {
  text-decoration: none;
}

.starReviewsUnclickable {
  color: var(--successColor);

  justify-content: flex-start;
}
.reviewsNumberUnclickable {
  color: var(--successColor);
}
.starReviewsUnclickable svg,
.starReviewsUnclickable span,
.starReviewsUnclickable p {
  margin-right: 0.2em;
}

.whiteStroke {
  stroke: #d3d3d3 !important;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  color: white !important;
  background-color: white !important;
  fill: white !important;
  z-index: 1000 !important;
}

.whiteText {
  justify-content: flex-start;
  color: white !important;
  font-weight: 600;
  z-index: 1000;
  margin-bottom: 8px;
}

.blackText {
  justify-content: flex-start;
  color: #333 !important;
  font-weight: 600;
  z-index: 1;
  margin-bottom: 8px;
}
