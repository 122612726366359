@import '../../styles/marketplaceDefaults.css';

/* @import '../../ContactPage.module.css'; */

/* font converted using font-converter.net. thank you! */
/* font converted using font-converter.net. thank you! */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}


.formGroup {
  width: 90%;
  margin: 0 auto;
}

.pageTitle_small {
  /* @apply --SectionHero_animation; */

  font-family: "GT Super Display Bold", "Canela-Medium", Helvetica, Arial, sans-serif;
  letter-spacing: .5px;
  position: relative;
  margin-top: 20px;
  font-size: 24px;
  font-weight: var(--fontWeightBlack);
  line-height: 34px;
  text-align: center;
  z-index: -1;
  width: 90vw;
  margin-left: 5vw;
  color: var(--matterColor);

  @media (--viewportMedium) {
    width: 20em;
    font-size: 34px;
    font-weight: var(--fontWeightBlack);
    line-height: 50px;
    margin: 30px auto 0 auto;
  }
}

.contactForm_hide {
  display: none;
}

.contactForm_hidden {
  visibility: hidden;
}

.form_control {
  /* @apply --SectionHero_animation; */
  background-color: #f5f9f8;
  border: 1px solid #1c7881;
  border-radius: 5px;
  padding: 1em;
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;


  @media (--viewportMedium) {
    width: 20rem;
  }
}

.form_control:active {
  border: 1px solid #1c7881;
}

input:focus,
textarea:focus {
  background-color: #fff;
  border: 1px solid #1c7881 !important;
  box-shadow: none !important;
  transition: all ease-in-out 100ms;
}

.form_control_hide {
  display: none;
}

.form_control_big {
  /* @apply --SectionHero_animation; */
  background-color: #f5f9f8;
  border: 1px solid #1c7881;
  border-radius: 5px;
  padding: 1em;
  width: calc(90vw - 2em);
  margin: 1em auto;

  @media (--viewportMedium) {
    width: 30em;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}

.submitButton {
  border: none;
  border-radius: 10px;
  background-color: #eb7242;
  color: #fff;
  text-align: center;
  text-decoration: none;
  transition: all .1s ease-in-out;
  cursor: pointer;
  min-height: 59px;
  margin: 0;
  padding: 17px 0;
  animation-delay: 0.8s;
  margin-top: 20px;
  display: block;

  width: 90vw;
  margin-left: 5vw;

  position: relative;
  z-index: 9;

  @media (--viewportMedium) {
    top: unset;
    margin: 25px auto;
    width: 260px;
  }
}

.submitButtonEmpty {
  background-color: #1c7881;
  color: #f5f9f8;
  position: relative;
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
  margin-top: 20px;
  min-height: 59px;
  padding: 17px 0;
  display: block;
  width: 90vw;
  margin-left: 5vw;
  pointer-events: none;
  opacity: .5;
  z-index: 9;
  border-radius: 11px;
  pointer-events: none;
  opacity: 0.5;
  z-index: 9;
  font-family: "poppins", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .2px;
  text-align: center;

  @media (--viewportMedium) {
    top: unset;

    margin: 25px auto;
    width: 260px;
  }
}

.response {
  position: absolute;
}

.pageTitle_response {
  /* @apply --SectionHero_animation; */
  font-family: var(--fontFamilyTitle);
  letter-spacing: 0.5px;
  position: absolute;
  margin-top: 30px;
  width: 90vw;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  z-index: -1;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    width: 20em;
    font-size: 34px;
    line-height: 50px;
    margin: 50px auto 0 auto;
    color: var(--marketplaceColor);
  }
}

/* 
@media screen and (max-width: 576px) {
  .pageTitle_small {
    position: static;
    margin-top: 10px;
    width: 15em;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    line-height: 28px;
  }

  .form_control {
    width: 15em;
    height: 40px;
  }

  .form_control_big {
    width: 15em;
    margin-top: 10px;
    height: 50px;
  }

  .submitButton {
    position: static;
    margin-right: auto;
    margin-left: auto;
    width: 180px;
    margin-top: 15px;
    width: 180px;
    margin-top: 15px;
  }

  .submitButtonEmpty {
    position: static;
    margin-right: auto;
    margin-left: auto;
    width: 180px;
    margin-top: 15px;
  }

  .pageTitle_response {
    margin-top: 10px;
    width: 15em;
    margin-left: -7em;
    font-size: 17px;
    line-height: 28px;
  }
} */
 