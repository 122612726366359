@import '../../styles/marketplaceDefaults.css';

.root {}

/**Global scope css variable root**/

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
}

.container {
  background-color: rgb(250, 250, 250);
  padding-top: 5em;
}

/**Shared css classes*/
.cardText {
  color: #555;
  font-size: 18px;
  font-style: normal;
  word-break: break-word;
  line-height: 1.4;
  word-break: break-word;
}

.divPositionCol {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  padding: 15px;
  font-weight: 700;
  font-size: 30px;

  color: #1c7881;
}

@media screen and (max-width: 576px) {
  .sectionTitle {
    font-size: 25px;
    width: 80vw;
  }
}

@media screen and (max-width: 576px) {
  .sectionSubTitle {
    width: 80vw;
  }
}

.sectionSubTitle {
  padding: 15px;
  color: #555;

  font-style: normal;
  word-break: break-word;
  line-height: 1.5;
}

.wrapperPosition {
  padding: 20px;
  position: relative;
  padding-bottom: 6em;
}

/**Accommodation section css*/
.accomodationSection {
  width: 80vw;
  margin: auto;
}

.accCardWrapper {
  background-color: #fff;
  border: 0.5px solid rgba(100, 146, 253, 0.2);
  border-radius: 10px;
  box-shadow: 0 11px 30px rgba(154, 161, 177, 0.2);
  margin: 10px;
  max-width: 25rem;
  max-height: 25rem;
}

.accommodationTitleSection {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.accCardOuterWrapper {
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
}

@media screen and (min-width: 577px) and (max-width: 992px) {
  .accCardOuterWrapper {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .accCardOuterWrapper {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.accCardTextArea {
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 150px;
  /* Set a fixed height for the wrapper */
}

.cardTitle {
  color: #1c7881;

  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0.5rem;
  line-height: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardDescription {
  margin: 0;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.imageArea {
  border-radius: 5px;
  cursor: pointer;
}

.accCardPhoto {
  height: 200px;
  width: 100%;
  object-fit: fill;
  border-radius: 10px 10px 0 0;
  max-width: 100%;
}

/*How it works section css*/
.worksSection {
  width: 80vw;
  margin: auto;
}

.worksContainer {
  padding: 0.5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(50px, auto);
}

@media screen and (max-width: 576px) {
  .worksContainer {
    text-align: left;
    margin-left: 5%;
  }
}

.gridItem {
  background-color: aquamarine;
  border: 1px solid wheat;
}

.item1 {
  padding: 15px;
  grid-column: span 2;
  color: #555;

  font-style: normal;
  word-break: break-word;
  line-height: 1.5;
}

.item2 {
  grid-row: span 2;
  height: 97%;
  overflow: hidden;
  margin-right: 1vw;
}

.item2 img {
  margin-top: 20px;
  margin-left: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item3 {
  grid-row: span 2;
  margin-right: 1vw;
}

.listTitle {
  font-weight: bold;
  font-size: 15px;
}

.txtWrapper {
  white-space: initial;
}

.itemNumber {
  padding-right: 4px;
  font-weight: bold;
  font-size: 25px;
  color: #1c7881;
}

@media screen and (max-width: 576px) {
  .worksContainer {
    padding: 0.5%;
  }

  .item1 {
    padding: 0;
  }

  .item2 {
    grid-column: span 2;
  }

  .item2 img {
    margin-left: 0;
  }

  .item3 {
    grid-column: span 2;
  }
}

/*Testimonials section css*/
.testimonialsSection {
  width: 80vw;
  margin: auto;
}

.topbarWrapper {
  width: 100vw;
}

.testimonialsTitle {
  padding: 15px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 30px;
  color: #1c7881;
}

.testiMonialsWrapper {
  width: 80vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (min-width: 577px) and (max-width: 992px) {
  .testiMonialsWrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .testiMonialsWrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/**CSS for testimonials card**/
.cardWrapper {
  background-color: #fff;
  border: 0.5px solid rgba(100, 146, 253, 0.2);
  border-radius: 10px;
  box-shadow: 0 11px 30px rgba(154, 161, 177, 0.2);
  padding: 18px 25px 25px;
  margin: 10px;
  max-width: 25rem;
}

.cardUserInfo {
  display: flex;
  flex-direction: row;
}

.userImg {
  height: 50px;
  width: 50px;
  margin: 20px 20px 0px 0px;
  border-radius: 50px;
}

.locationTxt {
  color: #1c7881;
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0 0 8px;
}

.userNameTxt {
  font-weight: bolder;
  font-size: 16px;
}

/**Question Answer section css*/

.questionCardOuterWrapper {
  margin-left: 1%;
}

.questionCardSection {
  width: 80vw;
  margin: auto;
}

.questionAnsTitle {
  padding: 15px;
  margin-left: 40px;
  font-weight: bold;
  font-size: 30px;
  color: #1c7881;
}

.questionCardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 80vw;
  border-bottom: 0.5px solid #555;
  margin: auto;
}

@media screen and (max-width: 576px) {
  .questionCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    border-bottom: 0.5px solid #555;
    margin: auto;
  }

  .questionCardOuterWrapper {
    margin-left: 4%;
  }
}

.quesWrapper {
  width: 40%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.quesWrapper>h3 {
  color: var(--successColor);

  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 576px) {
  .quesWrapper {
    width: 90%;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
  }
}

.ansWrapper {
  width: 60%;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 576px) {
  .ansWrapper {
    width: 90%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.ansWrapperMobile {
  display: none;
}

/* HERo */
.heroSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  margin: auto;
  padding: 20px;
}

.heroSubText {
  width: 80vw;
  margin: auto;
  padding: 20px;
}

.heroButton {
  @apply --marketplaceButtonStylesPrimary;
  width: 40vw;
  font-size: clamp(1rem, 3vw, 1rem);
  margin: 10px 0 30px 0;
  border-radius: 11px;

  @media only screen and (min-width: 768px) {
    width: 30vw;
  }

  @media only screen and (min-width: 900px) {
    width: 15vw;
  }
}

.closeButton {
  cursor: pointer;
}

.heroText {
  width: 100%;
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heroTitle {
  font-size: clamp(2rem, 6vw, 3.7rem);
  margin-top: 0;
  line-height: 1.2;
  color: var(--attentionColor);
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.heroSubtitle1 {
  font-size: clamp(1.3rem, 3vw, 1.7rem);
  font-weight: var(--fontWeightBlack);
  margin-top: 2rem;
  color: #888;
  line-height: 1.2;
}

.heroSubtitle2 {
  font-size: clamp(1.3rem, 5vw, 1.7rem);
  font-weight: var(--fontWeightBlack);
  margin-top: 0.5rem;
  color: #4a4a4a;
  line-height: 1.2;
}

.heroDescription {
  font-size: clamp(1rem, 3vw, 1.3rem);
  line-height: 1.5;
  color: #555;
  margin-top: 1rem;
  padding-right: 5px;
}

.heroImageContainer {
  width: 100%;
  order: 2;
  margin-top: 1rem;
}

.heroImage {
  display: block;
  max-width: 100%;
  max-height: 80vh;
  margin-left: auto;
}

.formWrapper {
  height: 100%;
  padding: 16px;
  margin-bottom: 400px;
}

.hubspotDrawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 35%;
  background-color: white;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  padding-top: 20px;
  box-shadow: -89px -1px 148px 28px rgba(0, 0, 0, 0.76);
  -webkit-box-shadow: -89px -1px 148px 28px rgba(0, 0, 0, 0.76);
  -moz-box-shadow: -89px -1px 148px 28px rgba(0, 0, 0, 0.76);

  @media only screen and (max-width: 767px) {
    width: 80%;
  }
}

@media only screen and (min-width: 767px) {
  .heroSection {
    flex-wrap: nowrap;
  }

  .heroTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .heroText {
    width: 50%;
    order: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .heroDescription {
    font-size: 1rem;
  }

  .heroImageContainer {
    width: 50%;
    order: 1;
    margin-top: 0;
  }
}

.hsForm {
  height: 80%;
}
