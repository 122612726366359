@import '../../styles/marketplaceDefaults';
@import '../../styles/customMediaQueries.css';

.footerContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom: -500px !important;
  padding-bottom: 0px !important;
  position: relative;
  font-weight: 400;
}

.footerBottom {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  font-size: 1rem;
  height: auto;
  max-height: 600px;
  background-color: #1c7881;
}

.list1,
.list2,
.list3 {
  color: white;
}

.heading {
  color: white;
  font-size: 1.3rem;
  margin-bottom: 1em;
}

.link {
  color: white;
  text-decoration: none;
  display: flex;
  padding: 3px 0;
}

.link:hover {
  text-decoration: underline;
}

.listItem {
  white-space: nowrap;
}

.leftList {
  margin-bottom: 0em;
}

/* Copyright */
.copyright {
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  text-align: center;
}

.footerBottomContainer {
  display: flex;
  width: 100%;
  max-width: 1500px;
  padding-top: 15px;
  justify-content: space-between;
  @media screen and (max-width: 1540px) {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 20px;
    padding-top: 25px;
  }
  @media screen and (max-width: 550px) {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.bottomSection {
  width: 100%;
  max-width: 1500px;
  height: fit-content;
  display: flex;
  justify-content: center; 
  align-items: center;    
  padding: 1em;       
  gap: 1.5em;  
  margin: auto;    
  border-top: 2px solid white;       

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.languageSwitcher {
  display: flex;
  width: fit-content;
  align-items: center;
}

.dataSection {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  justify-content: center;
  gap: 30px;
  white-space: nowrap;
}

.dataSectionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  white-space: nowrap;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.spacer {
  height: 30px !important;
  display: flex;
  width: 2px !important;
  background-color: white;
}

.linkText {
  white-space: nowrap;
}

.copyrightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  @media screen and (max-width: 1000px) {
    .space {
      display: none;
    }
  }
}

.tocBottomContainer {
  display: flex;
  width: 30%;
  justify-content: space-between;
  padding: 0 1em;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
  white-space: nowrap;
  padding-left: 12%;
}

.bottomSectionMobile {
  display: none;
  @media screen and (max-width: 1000px) {
    border-top: 2px solid white;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }
}

.mobileLinksContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
  white-space: nowrap;
  padding-left: 12%;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    width: 97%;
    padding: 0;;
    gap: 10px;
  }
}

.mobileColumn {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.socialMediaIcon {
  width: 60px;
  height: 60px;
  @media screen and (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
}

.socialMediaBtns {
  display: flex;
  width: fit-content;
  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.listItemHeader {
  white-space: nowrap;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.bottomSectionWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerTopText {
  margin-top: -8px;
}

.bottomSectionDesktop {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 2px solid white;
  align-items: center;
}
