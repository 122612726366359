@import '../../styles/marketplaceDefaults.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  z-index: 100;
}

.circularAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderImage1,
.loaderImage2 {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.loaderImage2 {
  animation-delay: 0s !important;
  animation: rotate 4s linear infinite;

  z-index: 1000;
}

.loaderImage1 {
  /* animation: move 4s linear infinite; */
  z-index: 1000;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(50px, 0);
  }

  50% {
    transform: translate(0, 50px);
  }

  75% {
    transform: translate(-50px, 0);
  }
}

.topbar {
  opacity: 0;
  z-index: 999;
  background-color: transparent;
}

.Wrapper {
  margin-top: -72px;
  padding-top: 72px;
  width: 100%;
  height: 100%;
  z-index: 9;
  @apply --marketplacePaperBackground;
}

.section {
  position: relative;
  height: auto;
  overflow: hidden;

  @media (--viewportMedium) {
    position: relative;
  }
}

.section2 {
  @media (--viewportMLarge) {
    margin-top: -15vh;
  }
}

.section4 {
  z-index: 1;
  height: auto;
  position: relative;
  height: auto;
  overflow: hidden;
}

.section5 {
  z-index: 0;
  min-height: 10vh;
}

.dots {
  margin-top: -60vh;
  margin-left: 50px;
  width: 10vw;
}

.dot_active {
  background-color: var(--marketplaceColorSecond);
  border: 1px solid var(--marketplaceColorSecond);
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 100%;
  margin-top: 10px;
  transition: 0.5s;
}

.dot {
  background-color: transparent;
  border: 1px solid var(--marketplaceColorSecond);
  width: 10px;
  height: 10px;
  margin-left: 5px;
  position: relative;
  border-radius: 100%;
  margin-top: 10px;
  transition: 0.5s;
}

.dotInner {
  width: 100%;
  height: 100%;

  position: absolute;
}

.LeftFooterContent {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 80px;
    z-index: 10;
    position: fixed;
    bottom: 60px;
    /* margin-left: 50px; */
    margin-left: 27px;
  }
}

.socialmedia {
  width: 50px;
}

.firstWrapper {
  position: relative;
  height: 50vh;

  @media (--viewportMedium) {
    height: 100vh;
  }
}

@keyframes startImageAnimation {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes startImageAnimation2 {
  0% {
    transform: skewX(5deg) translateY(50px);

    opacity: 0;
  }

  33% {
    transform: skewX(5deg) translateY(50px);

    opacity: 0;
  }

  100% {
    transform: skewX(0deg) translateY(0px);
    opacity: 1;
  }
}

.cloudsOuter {
  will-change: auto;
  animation-delay: 0s !important;
  opacity: 0;
  transform: translate3d(-35vw, 0px, 0px);
  z-index: 9;
  top: -2em;
  position: absolute;
  width: 100vw;

  @media (--viewportMedium) {
    top: -6em;
  }
}

.cloudsOuterVisible {
  opacity: 1;
  animation: cloudsanimation 50s ease-in-out infinite;
}

.clouds {
  opacity: 0;
  will-change: auto;
  clip-path: circle(100% at 50% 40%);
  width: 550%;
  margin-top: -15vh;
  margin-left: -200%;
  height: 100vh;

  @media (--viewportMedium) {
    z-index: 8;
    position: absolute;
    top: 0;
    margin-top: -25vh;
    margin-left: -30vw;
    width: 150%;
  }
}

.cloudsVisible {
  opacity: 1;
}

@keyframes cloudsanimation {
  0% {
    transform: translate3d(-35vw, 0px, 0px);
  }

  50% {
    transform: translate3d(-40vw, 0px, 0px);
  }

  100% {
    transform: translate3d(-35vw, 0px, 0px);
  }
}

.line {
  height: 1px;
  width: 65vw;
  margin-left: -14vw;
  background-color: black;
  margin-top: 200px;
  margin-bottom: 0px;
}

.HeaderWrapper {
  position: absolute;
  top: 34%;
  left: 40%;
  transform: translate(-40%, -50%);
  z-index: 10;
}

.Header1 {
  text-align: center;
  margin-top: -5px;
  margin-bottom: -5px;
  width: 100vw;
  margin-left: 0vw;
  z-index: 5;
  font-size: 44px;
  line-height: 66px;
  opacity: 0;
  text-shadow: 0 0 15px rgba(28, 120, 129, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0 0 15px white, 0 0 15px white;

  @media (--viewportMedium) {
    width: 100vw;
    font-size: 44px;
    line-height: 84px;
  }

  @media (--viewportMLarge) {
    font-size: 64px;
    line-height: 114px;
  }
}

.Header1Visible {
  opacity: 1;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.SubHeader1 {
  opacity: 0;
}

.SubHeader1 h2 {
  text-align: center;
  font-size: 18px;
  font-family: Noto Sans JP;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  line-height: 24px;
  margin-top: 10px;
  width: 70vw;
  margin-left: 15vw;
  color: var(--marketplaceColorSecond);

  @media (--viewportMedium) {
    margin-top: 0px;
    font-size: 24px;
    line-height: 54px;
  }
}

.SubHeader1Visible {
  opacity: 1;
  -webkit-animation: slide-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* image in the very beginning (a girl sitting on a boat) */
.heroImage {
  will-change: auto;
  opacity: 0;
  position: absolute;
  transform: scale(1);
  height: 100vh;
  width: 100vw;
  margin-left: 0;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
}

.heroImageVisible {
  opacity: 1;
  -webkit-animation: kenburns-top 8s ease-out reverse both;
  animation: kenburns-top 8s ease-out reverse both;
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }

  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }

  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.sectionHeader {
  @apply --marketplacePaperBackground;

  z-index: 1;
  width: auto;
  line-height: 34px;
  position: absolute;
  font-family: var(--fontFamilyTitle);
  margin-left: 0;
  text-align: left;
  padding-left: 20px;
  font-size: 24px;

  margin-top: 8vh;

  @media (--viewportMedium) {
    text-align: center;
    margin-top: 15vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }

  @media (--viewportLarge) {
    width: auto;
    text-align: center;
    margin-top: 15vh;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 34px;
    line-height: 44px;
  }
}

.lineCarousel {
  position: absolute;
  height: 1px;
  width: 100vw;
  left: 0vw;
  float: left;
  background-color: var(--marketplaceColor);
  margin-top: 23vh;

  @media (--viewportMedium) {
    margin-top: 17.5vh;
  }
}

.videoWrapper {
  height: auto;
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  margin-top: 15vh;
  animation: videoWrapperAnimation 3.5s;

  @media (--viewportMedium) {
    flex-direction: row;
    padding-bottom: 0px;
    margin-left: 100px;
    width: calc(100vw - 200px);
    bottom: 50px;
    border-radius: 11px;
    height: calc(100vh - 162px);
    position: absolute;

    margin-top: 0;
  }
}

@keyframes videoWrapperAnimation {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  40% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.showVideoicon {
  width: 64px;
  height: 64px;
  background-color: var(--marketplaceColorSecond);
  position: absolute;

  border-radius: 200px;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
}

.showVideoicon:hover {
  filter: drop-shadow(0px 4px 15px rgba(235, 114, 66, 0.4));
}

.showVideoicon:after {
  position: absolute;
  border-left: 12px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  margin-left: 2px;
  content: '';
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.storyTextHeaderWrapper {
  width: 100%;
}

.whyWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  width: 90vw;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.whyTextWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding: 12px;
    align-items: center;
  }
}

.whyTextHeader {
  composes: h2 from global;

  width: 100%;
  text-align: left;
  color: var(--successColor);
  white-space: pre-wrap;
}

.whyTextBlock2Header {
  @media (--viewportMLarge) {
    padding-right: 2.7rem;

    .imagesWrapper {
      width: 90vw;
      margin: 0 auto;
      margin-bottom: 90px;
      position: relative;
      display: flex;
      flex-direction: row;
      width: 250px;
    }
  }
}

.SubHeaderImage {
  width: 50px;
  margin-right: 20px;
  float: left;
  margin-top: 0px;
}

/* paragraph for our team*/
.text {
  color: var(--successColor);
  text-align: justify;
  @apply --marketplaceMediumTextStyles;

  @media (--viewportMedium) {
    text-align: justify;
  }
}

.storyWrapper {
  margin-top: 70px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 70px;
  }
}

/* this is the paragraph beside the picture of a man teaches his daughter */
.storyTextWrapper {
  width: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    margin: 0;
    flex: 1;
    width: 40%;
  }

  @media (--viewportMLarge) {
    max-width: 700px;
  }
}

.storyPhotoWrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 370px;
  margin-top: 60px;

  @media (--viewportMedium) {
    width: 60%;
    max-width: 40rem;
    transform: translateX(0);
    left: 0;
  }
}

/* this is a picture of a man teaches his daughter  */
.storyPhoto1,
.storyPhoto2 {
  opacity: 1;
  transition: 4s;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media (--viewportMedium) {
    transform: translateX(0);
    left: 0;
  }
}

.storyPhoto1 {
  position: relative;
}

.storyPhoto2 {
  position: absolute;
}

.storyPhoto_hide {
  position: absolute;
  left: 50%;
  opacity: 0;
  transition: 4s;
  width: 90%;
  max-width: 370px;
  margin-top: 10vh;

  @media (--viewportMedium) {
    max-width: 40rem;
    width: 60%;
    left: 0;
    margin-top: -5vh;
    margin-left: -10vw;
    transform: scale(0.5);
  }
}

.teamWrapper {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
  }
}

.teamTextWrapper {
  width: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    margin: 100px 0 0 0;
    width: 40%;
    flex: 1 1 40%;
    order: 1;
  }

  @media (--viewportMLarge) {
    max-width: 700px;
  }
}

.teamPhoto {
  opacity: 1;
  transition: 4s;
  width: 90%;
  max-width: 370px;

  @media (--viewportMedium) {
    width: 60%;
    max-width: 600px;
    flex: 2 1 60%;
    order: 2;
  }
}

.teamPhoto_hide {
  position: absolute;
  margin-left: 25vw;
  opacity: 0;
  transition: 4s;
  width: 80vw;
  margin-top: 10vh;

  @media (--viewportMedium) {
    width: 45vw;
    margin-top: 10vh;
    right: 0;
    margin-right: 5vw;
  }
}

/* this is for house  +  girl with the bag */
.imagesWrapper {
  width: 90vw;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.imagesItemsInner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (--viewportMedium) {
    width: 12%;
    margin-right: 70px;
  }

  @media (--viewportLarge) {
    width: 12%;
    margin-right: 100px;
  }
}

.houses {
  flex-basis: 300%;

  @media (--viewportMedium) {
    flex-basis: unset;
    width: 40%;
  }
}

.traveller {
  @media (--viewportMedium) {
    width: 11%;
    margin-right: 150px;
  }

  @media (--viewportLarge) {
    width: 11%;
    margin-right: 250px;
  }
}

.imagesItemsOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imagesWrapper img {
  width: 100%;
}

.contactFormWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  @apply --marketplacePaperBackground;
}

.line1 {
  /* @apply --SectionHero_animation; */
  position: absolute;
  height: 1px;
  width: 45vw;
  left: 0vw;
  margin-top: 200px;
  background-color: var(--marketplaceColor);
}

.partnersWrapper {
  width: 90vw;
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  margin: 2rem auto;
}

.partnersHeader {
  composes: h2 from global;
  width: 100%;
  text-align: left;
  color: var(--successColor);
}

.articles {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  width: 90vw;
  position: relative;
  margin: 0 auto;
}

/* this is for 11 of the icons from partnership */
.articlesItems {
  width: 25%;
  height: auto;
  max-width: 200px;
  filter: grayscale(100%);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  @media (--viewportMedium) {
    width: 16%;
    height: 150px;
    max-width: unset;
  }
}

.articlesItems img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.articlesItems:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.heroButton {
  @apply --marketplacePaperBackground;
  @apply --marketplaceButtonStyles;
  border-radius: 11px;
  z-index: 0;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  position: block;
  animation: startAnimation 5s;
  width: 100%;
  margin: 0 auto 30px auto;

  @media (--viewportMedium) {
    width: 250px;
  }
}

/*Where does the money go section*/

.section_graph {
  /* display: inline-block;
  flex-direction: row; */
  flex-direction: row;
  width: 90vw;
  padding: 70px 0;
  margin: 0 auto;

  @media (--viewportSmall) {
    flex-direction: row;
    display: flex;
    padding: 70px 0;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 1025px) and (max-width: 1400px) {
    flex-direction: row;
    width: 100%;
    display: flex;
    padding: 70px 0;
    margin: 0 1em 0 -3em;
    justify-content: center;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row;
    width: 100%;
    display: flex;
    padding: 70px 0;
    margin: 0 0 0 -6em;
    justify-content: center;
  }
}

.section_graph h1 {
  text-align: center;
  width: 100%;

  @media (--viewportSmall) {
    text-align: left;
    flex-grow: 1;
    flex-basis: 50%;
    width: 100%;
  }
}

.middleLine {
  display: none;
  margin: 0px;
  margin-right: 100px;

  @media (--viewportSmall) {
    display: block;
    width: 0.6vw;
    height: 46vh;
    background-color: #1c7881;
    border-top: none;
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    margin-left: 70px;
    margin-right: 120px;
  }

  @media (max-width: 900px) {
    margin-right: 18px;
  }
}

.Layer_1 {
  margin-left: 20px;
  fill: #ea7141;
  transform: none;
  flex-grow: 1;
  flex-basis: 100%;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20%;
  margin-bottom: 30px;
  overflow: visible !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  @media (--viewportSmall) {
    flex-grow: 1;
    flex-basis: 50%;
    width: 85%;
    margin-top: 70px;
    display: inline-block;
    margin-left: 30px;
    /*     display: flex; */
  }

  @media (max-width: 900px) {
    /*     flex-grow: 1; */
    flex-basis: 100%;
    /* width: 90%; */
    /* margin-left: 20px;
    margin-top: 100px; */
    margin: 4em auto 1em auto;
  }

  @media (min-width: 1026px) {
    width: 120%;
  }
}

.textUnderWhereDoesMyMoneyGo {
  width: 70%;
  display: inline-block;
  text-align: center;

  @media (--viewportSmall) {
    width: 65%;
  }
}

.leftOGraph,
.rightBGraph,
.rightgraph,
.leftgraph,
.leftline,
.rightline {
  transition: all 0.5s ease-in-out;
}

.rightgraph,
.rightline,
.rightGtxt {
  fill: var(--successColor);
}

.blueTitle {
  composes: h2 from global;
  color: var(--successColor);

  @media (--viewportSmall) {
    color: var(--successColor);
  }
}

.orangeTitle {
  composes: h2 from global;
  color: var(--marketplaceColorSecond);
}

.leftgraph,
.leftline,
.leftGtxt {
  fill: var(--marketplaceColorSecond);
}

.rightBGraph:hover .rightgraph {
  transform: scale(1.2) translate(-103px, -154px);
  cursor: pointer;
}

.leftOGraph:hover .leftgraph {
  transform: scale(1.2) translate(-114px, -155px);
  cursor: pointer;
}

/*.leftOGraph:hover :not(.leftgraph) {
  display: none;
}
.rightBGraph:hover :not(.rightgraph) {
  display: none;
} */

.graphText {
  /* margin-left: 20px; */
  width: 100%;

  @media (--viewportSmall) {
    width: 40%;
  }
}

.graphText h3 {
  color: #aeaeae;
  letter-spacing: 5px;
  line-height: 30px;
  margin-bottom: 20px;
  display: inline-block;
  text-align: center;
  width: 90%;
  font-size: 28px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.graphText p {
  font-size: 20px !important;
}

.frameForTheTexts {
  height: 500px;
  margin-left: 10px;
  width: 700px;

  @media (--viewportSmall) {
    margin-left: 100px;
  }
}

.blueText {
  width: 70%;

  @media (--viewportSmall) {
    width: 65%;
  }
}

.orangeText {
  width: 70%;
  justify-content: center;

  @media (--viewportSmall) {
    width: 65%;
    justify-content: center;
  }
}

.divider {
  border: 2px solid rgb(201, 199, 199);
  border-radius: 10px;
  max-width: 320px;
  height: 2;
  background-color: rgb(201, 199, 199);
  margin: 30px auto;

  @media (--viewportSmall) {
    border: 2px solid rgb(201, 199, 199);
    margin-bottom: 80px;
    max-width: 1300px;
    height: 2;
    border-radius: 10px;
    background-color: rgb(201, 199, 199);
    margin-top: 80px;
  }
}
