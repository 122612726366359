
    @apply --marketplaceButtonFontStyles;

    
    display: block;
    width: 100%;
    min-height: 59px;
    margin: 0;

    
    padding: 17px 0 17px 0;

    

    
    border: none;
    border-radius: 10px;

    
    background-color: var(--matterColorLight);
    color: var(--marketplaceColor);

    
    text-align: center;
    text-decoration: none;

    
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
      color: var(--marketplaceColor);
    }

    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 65px;
    }
  