@import '../../styles/marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  border-radius: 11px;
  overflow: hidden;
  width: 340px;
  height: 120%;
  box-shadow:  0 0 20px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  background-color: #fff;

  &:hover {
    text-decoration: none;

    transform: scale(1.02);
  }
}

.rootSecond {
  /* Layout */
  display: flex;
  flex-direction: row;
  border-radius: 11px;
  border: 2px solid var(--marketplaceColor);
  overflow: hidden;
  width: 340px;
  height: 120%;
  box-shadow:  0 0 20px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  background-color: #fff;

  &:hover {
    text-decoration: none;

    transform: scale(1.02);
  }
}

.location {
  font-size: 14px;
  /* line-height: 32px; */
  margin-top: 0;
  margin-bottom: 0;
  color: #353535;

  @media (--viewportMedium) {
  }
}

.price_wrapper {
  margin-top: 0px;

  margin-right: 20px;
}
.price {
  /* Layout */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px; */
  position: relative;
  float: right;
  right: 0;
  justify-content: flex-end;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: #1c7881;
  font-weight: bold;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportMaxSmallDevice) {
    font-size: 14px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
}

.priceSubTxt {
  font-weight: 300;
  color: #353535;
  padding-right: 5px !important;
  @media (--viewportMaxSmallDevice) {
    font-size: 14px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.info {
  height: 100%;
  width: 100%;
  margin-left: -8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.mainInfo {
  height: 100%;
  width: 60%;
  margin-left: -35px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.title {
  /* Font */
  /* @apply --marketplaceH5FontStyles; */
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 18px;
  color: #353535;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 18px;
  }

  @media (--viewportMaxSmallDevice) {
    font-size: 17px;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.iconStyle {
  width: 43px;
  height: 45px;
  margin-right: -20px;
  padding-right: 0px;
  padding-left: 0px;
  @media (--viewportMaxSmallDevice) {

    padding-right: 0px;
  }
}
.expImage {
  width: 65px;
  height: 65px;
}
.expImageContainer {
  padding-right: 0px;
}
