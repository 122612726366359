@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.mainWrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: 1300px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  @media screen and (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }
  @media screen and (min-width: 1800px) {
    max-width: 1450px;
  }
}

.formWrapper {
  background-color: white;
  border: 3px solid var(--marketplaceColor);
  border-radius: 15px;
  padding: 26px;
  max-width: 600px;
  width: 100%;
}

.formInput,
.formInput:hover,
.formInput:focus {
  border: 1px solid var(--marketplaceColor) !important;
  margin-top: 10px;
  color: black;
  height: 40px;
  border-radius: 10px;
  &:active {
    border: 3px solid var(--marketplaceColor) !important;
  }
}

.inputLabel2 {
  margin-top: 36px;
  width: 100%;
  @media screen and (--viewportMedium) {
    margin-top: 12px;
  }
}

.formWrapper .buttonGroup {
  display: flex;
  justify-content: start;
  color: var(--marketplaceColor);
  flex-direction: column;
}

.formWrapper .buttonGroup button {
  border: 1px solid var(--marketplaceColor);
  font-size: 1.1em;
  font-weight: 400;
  &:hover{
    transform: scale(1.01);
  }
  &:active{
    transform: scale(0.99);
  }
}

.formLabel {
  color: var(--marketplaceColor);
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: 900;
}

.btn {
  white-space: nowrap;
  width: fit-content !important;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #00797b;
  color: #00797b;
  cursor: pointer;
  border: none;
  font-size: 17px;
  @media screen and (max-width: 490px) {
    white-space: inherit;
  }
}

.btnsWrapper {
  margin-top: 35px;
  @media screen and (--viewportMedium) {
    margin-top: 10px;
  }
}

.submitBtn {
  background-color: var(--marketplaceColorSecond);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 20px 40px;
  margin-top: 15px;
  cursor: pointer;
  &:hover{
    transform: scale(1.04);
  }
  &:active{
    transform: scale(0.99);
  }
}

.selectedBtn {
  background-color: #00797b;
  color: white;
}

.imgClass {
  object-fit: cover;
  width: 350px;
  height: 300px;
  @media screen and (max-width: 490px) {
    width: 180px;
    height: 160px;
  }
}
.imgWrapper {
  margin-right: 100px;
  display: flex;
  position: relative;
  @media screen and (max-width: 490px) {
    margin-top: -50px;
  }
}

.img1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 10px;
  left: 30px;
  bottom: 60px;
  transform: rotate(10deg);
  width: 370px;
  height: 420px;
  z-index: 2;
  transition: transform 1s ease-out;
  background-color: white !important;
  box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
  }
  &:hover{
    transform: rotate(6deg);
  }
}
.img2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 10px;
  right: -130px;
  bottom: 120px;
  transform: rotate(-4deg);
  width: 370px;
  height: 420px;
  background-color: white !important;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: transform 1s ease-out;
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
  }
  &:hover{
    transform: rotate(0deg);
  }
}

.img3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 10px;
  bottom: -100px;
  left: -140px;
  transform: rotate(30deg);
  width: 370px;
  height: 420px;
  box-shadow: 10px solid black;
  background-color: white !important;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.3);
  z-index: 3;
  transition: transform 1s ease-out;
  @media screen and (max-width: 490px) {
    width: 200px;
    height: 240px;
    bottom: 10px;
  }
  &:hover{
    transform: rotate(20deg);
  }
}

.imgCardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  translate: 150px 0;
  margin-top: 400px;
  @media screen and (--viewportLarge) {
    translate: 250px 0;
  }
}

.error {
  color: red;
  margin-top: 5px !important;
}

.extraInput{
  max-width: 400px;
}

.nameEmailWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 580px) {
    flex-direction: column;
    gap: 0;
  }
}
