@import '../../styles/marketplaceDefaults.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.Wrapper {
  margin-top: -72px;
  padding-top: 72px;
  width: 100%;
  height: 100%;

  @apply --marketplacePaperBackground;
}


.topbar {
  z-index: 999;
}

.LeftFooterContent {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 80px;
    z-index: 10;
    position: fixed;
    bottom: 60px;
    margin-left: 50px;
  }
}

.socialmedia {
  width: 50px;
}

.section {
  position: relative;
  height: auto;
  overflow: hidden;
  height: 10vh;

  /* @media (--viewportMedium) { // commented because on desktop footer can not be seen
    height: 100vh;
  } */
}
.FaqContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-top: 15vh;
  color: var(--successColor);
}

.FAQheader {
  font-family: Noto Sans JP;
  color: var(--attentionColor);
  text-align: center;
  margin-top: 28px;
  margin-bottom: 24px;
}

.heading {
  @apply --marketplaceH4FontStyles;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

button.showAll {
  @apply --marketplaceButtonFontStyles;
  display: block;
  outline: none;
  border: none;
  color: var(--successColor);
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 12px;
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}
