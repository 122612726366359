@import '../../../../../styles/customMediaQueries.css';
@import '../../../../../styles/marketplaceDefaults.css';

.outerWrapper {
  min-height: 100vh;
  width: 100%;
  background: white;
  margin-top: 10em;
  @media (--viewportMedium) {
    margin-top: 3em;
  }
}

.argsWrapper {
  background-image: linear-gradient(
    to top,
    #d5ecf9 0%,
    #d0eafa 5%,
    #fafafa 40%,
    #fafafa 100%,
    transparent 100%
  );

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.argsContainer {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (--viewportMedium) {
    display: block;
  }
  @media (max-width: 1024px) {
    margin: 0 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1440px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.birdsImage {
  position: absolute;
  top: 0;
  right: 150px;
  width: 250px;
  height: 250px;
  object-fit: contain;
}
.titleWrapper {
  width: 100%;
  max-width: 90%;
  @media (--viewportMedium) {
    max-width: 80%;
  }
}

.titleContent {
  margin-bottom: 3em;
  margin-top: 1em;
}

.sectionh2Title {
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}

.sectionDescription {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #4a4a4a;
  max-width: 650px;
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}

.reasonsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px 64px;
  max-width: 80%;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 36px !important;
    margin-left: 0;
  }
}

.reasonWrapper {
  display: flex;
  gap: 20px;
  min-height: 140px;
  transform: translateX(10%);
  @media (--viewportMedium) {
    transform: translateX(0%);
  }
}

.newsLetterContainer {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  bottom: 0;
  max-width: 1400px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    margin: 0 1em;
    bottom: 2em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
  }
  @media (min-width: 1300px) {
    margin: 0 7em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
  }
}

.newsLetterHeader {
  max-width: 90%;
  overflow: hidden;
  @media (--viewportMedium) {
    max-width: 47%;
  }
}

.newsletterContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.reasonId {
  font-size: 72px;
  font-weight: 900;
  color: #4a4a4a;
  font-family: Poppins, sans-serif;
  line-height: 1;
  min-width: 50px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 56px !important;
    font-weight: 600 !important;
  }
}

.reasonContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 70%;
  /*   @media screen and (max-width: 1024px) {
    max-width: 48%;
  } */
}

.reasonTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  height: 4em;
}

.reasonTitleWrapper2 {
  display: flex;
  flex-direction: column;
}

.reasonTitle {
  font-size: 1.1em;
  font-weight: 500;
  color: #1f2937;
  font-family: Poppins, sans-serif;
  line-height: 1.5em;
  flex: 1;
}
.reasonDescription {
  color: #4a4a4a;
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  @media screen and (max-width: 1024px) {
    line-height: 20px;
    font-size: 1em;
  }
}

.reasonTitleWrapper img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  flex-shrink: 0;
  margin-top: -0.25em;
  @media (--viewportMedium) {
    width: 80px !important;
    height: 80px !important;
    margin-top: -0.5em;
  }
}
.newsletterWrapper {
  background-image: url('../assets/mountan.webp');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  min-height: 74vh;
  padding: 0px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 120px;
  position: relative;
  margin-bottom: 50px;

  @media screen and (max-width: 1024px) {
    min-height: 90vh;
    background-size: cover;
    background-position: center;
  }
}

.newsletterTitle {
  text-wrap: nowrap;
  text-align: center;
  font-family: Noto Sans JP;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 600;
  width: 100%;

  @media (--viewportMedium) {
    text-align: left;
    width: 70%;
  }

  @media screen and (max-width: 1024px) {
    text-wrap: wrap;
    text-shadow: 2px 2px 5px rgba(0, 0, 1, 1);
  }
}
.newsletterDescription {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  max-width: 60%;
  margin-top: 2em;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  @media (--viewportMedium) {
    text-align: left;
  }
}
.newsletterFormWrapper{
  transform: translateX(10%);
  @media (--viewportMedium) {
    transform: translateX(0%);
  }
}
.newsletterFooter {
  position: absolute;
  bottom: 50px;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  text-align: center;
}
.reasonsContainer {
  display: flex;
}

.newsletterButton {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  outline: none;
  z-index: 1;
}

@media screen and (max-width: 1440px) {
  .birdsImage {
    right: 50px;
  }
  .newsletterDescription {
    max-width: 100%;
    text-shadow: 2px 2px 5px rgba(0, 0, 1, 1);
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .reasonsWrapper {
    margin-left: 0;
    gap: 30px 45px;
  }
  .reasonWrapper {
    gap: 16px;
  }
  .reasonId {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 72px;
    font-weight: 800;
  }
  .reasonTitle {
    max-width: 60%;
  }
  .reasonDescription {
    margin-top: 15px;
  }
  .titleWrapper {
    margin-left: 0;
  }
  .newsletterWrapper {
    display: grid;
    place-content: center;
    align-content: start;
    text-align: left;
    padding-top: 35vh;
    gap: 0px;
    @media screen and (max-width: 1024px) {
      padding-top: 16vh;
    }
  }
  .newsletterFooter {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
  .newsletterTitle {
    margin-bottom: 0px;
  }
  .birdsImage {
    top: -180px;
    @media (--viewportMedium) {
      top: -150px;
    }
  }
  .reasonTitleWrapper {
    display: block;
    position: relative;
  }
  .reasonTitleWrapper img {
    width: 60px;
    height: 60px;
    position: absolute;
    left: -65px;
    top: 75px;
  }
}
