@import '../../styles/marketplaceDefaults.css';
/* @import '../../ContactPage.module.css'; */

/* font converted using font-converter.net. thank you! */
/* font converted using font-converter.net. thank you! */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.Wrapper {
  margin-top: -72px;
  padding-top: 72px;
  width: 100%;
  height: 100%;

  @apply --marketplacePaperBackground;
}

.contactFormWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  top: 100px;
  left: 0;
  @apply --marketplacePaperBackground;

  h1,
  h2 {
    text-align: center;
  }
}

.line1 {
  /* @apply --SectionHero_animation; */
  position: absolute;
  height: 1px;
  width: 45vw;
  left: 0vw;
  margin-top: 150px;
  background-color: var(--successColor);
}

.line2 {
  /* @apply --SectionHero_animation; */
  position: absolute;
  height: 1px;
  width: 45vw;
  right: 0vw;
  margin-top: 150px;
  background-color: var(--successColor);
}

.contactPerson {
  /* @apply --SectionHero_animation; */
  margin-top: 80px;
  width: 70px;
  height: 70px;

  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 100px;
    width: 100px;
    height: 100px;
  }
}

.alternativeContact {
  padding: 1em;

  @media (--viewportMedium) {
    width: auto;
    height: auto;
    position: fixed;
    bottom: 0;
    right: 50px;
    padding: 1em;
  }
}

.alternativeContactTitle {
  display: none;
  font-weight: 600;
  color: var(--successColor);

  @media (--viewportMedium) {
    display: block;
  }
}

.socialmedia {
  width: 40px;
}

.linkContainer {
  display: flex;
  justify-content: space-evenly;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: column;
  }
}

.linkContainer:hover {
  color: red;
}

.socialLinkContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  position: relative;

  @media (--viewportMedium) {
    padding-top: 0;
    gap: 20px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1200px) {
  .line1 {
    width: 42vw;
  }

  .line2 {
    width: 42vw;
  }
}

@media screen and (max-width: 992px) {
  .line1 {
    width: 40vw;
  }

  .line2 {
    width: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .line1 {
    display: none;
  }

  .line2 {
    display: none;
  }

  .link:last-child {
    margin-left: 0px;
  }
}

@media screen and (max-width: 576px) {
  .contactPerson {
    margin-top: 80px;
    width: 70px;
    height: 70px;
  }
}
