@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  /* Layout */
  /*   padding: 0em; */
  /*  background-color: red; */
  padding-bottom: 50px;
  /*   background-color:  var(--colorGrey50); */
  border-radius: 10px;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  border-radius: 20px;
  height: 30vh;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 40px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  flex-shrink: 0;
  margin-top: 1px;
}

.priceValue {
  /* Font */
  font-size: 12px;
  line-height: 16px;
  font-weight: var(--fontWeightBold);

  /* Remove default margins from font */
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  flex-direction: row;
  gap: 2px;

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.priceText {
  color: var(--successColor);
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: space-between;
  gap: 5px;
  @media (--viewportMedium) {
    gap: 0;
  }
}
.roomtypePriceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  padding-top: 0;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  /* Font */
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  font-size: 1.4rem;
  margin: 0;
  width: 80%;
  font-family: Noto Sans JP;
  @media (--viewportMedium) {
    font-size: 1.05rem;
  }
}
.subtitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey600);
  font-size: 0.9rem;
  line-height: 1.6rem;
  margin: 0;
  width: 80%;
  @media (--viewportMedium) {
    font-size: 0.7rem;
    line-height: 1rem;
  }
}
.roomType {
  color: var(--successColor);
  font-weight: var(--fontWeightRegular);
  font-size: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: -12px;
  @media (--viewportMedium) {
    font-size: 0.8rem;
  }
}
.roomTypePriceContainer {
  display: flex;
  justify-content: center;
}
.categoriesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
}

.categoriesGroup {
  width: 100%;
  flex-grow: 1;
}

.categories {
  display: flex;
  flex-direction: row;
  height: 45px;
  width: 100%;
  position: relative;
  top: calc(50% - 22px);
  justify-content: flex-start;
}

.projectImpact {
  color: var(--successColor);
  font-weight: var(--fontWeightMedium);
  font-size: 0.8rem;
  position: relative;
  text-wrap: wrap;
  top: -0.5em;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 0.6rem;
    top: -1em;
  }
}
.categoryImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 5px;
  position: relative;
  top: calc(50% - 20px);
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.reviewWrapper {
  max-width: 150px;
  max-height: 50px;
  margin-top: 0px;
  margin-left: 0px;
}
.roomTypeText {
  padding-top: 2px;
  font-weight: 600;
  @media (--viewportMedium) {
    padding-top: 10px;
  }
}
.emptyPlaceholder {
  width: 10px;
  height: 45px;
  margin-top: 10px;
}

.slider__controls {
  pointer-events: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-right: 0px;
  right: 0px;
  z-index: 6;
  @media (--viewportMaxSmallDevice) {
    z-index: 7;
  }
  @media screen and (max-width: 850px) {
    left: -2px;
    margin-top: 38%;
  }
}

.btn {
  pointer-events: all;
  padding: 0.4em;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eb7242;
  border-radius: 50%;
  margin: 15px;
  width: 30px;
  height: 30px;
  color: #eb7242;
  z-index: 6;
  opacity: 1;
  @media (--viewportMedium) {
    opacity: 0;
  }
}

.btn img {
  width: 8px;
  height: auto;
  z-index: 6;
}

.btn:hover {
  cursor: pointer;
  background: rgb(236, 236, 236);
  z-index: 6;
}

.slider_btns {
  z-index: 6;
}

.root:hover .btn {
  opacity: 1;
}

.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.imageContainer {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.imageWrapper {
  min-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
}

.imageLoaded {
  opacity: 1;
}

.imageWrapper .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
