@import '../../styles/marketplaceDefaults.css';

.teamWrapper {
  width: 90vw;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  z-index: 3;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 900px) {
    justify-content: center;
    align-items: flex-start;
  }
}

.memberWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: auto;

  @media screen and (min-width: 850px) {
    min-height: 680px;
  }
}

.teamImagesWrapper {
  width: 90vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.teamTextHeader {
  composes: h2 from global;
  margin-bottom: 50px;
  width: 100%;
  text-align: left;
  color: var(--successColor);
}

.memberImage {
  position: absolute;
  width: 100%;
  height: auto;
}

.memberImage:hover {
  transform: scale(1.1);
}

.memberInfo {
  width: 360px;
  height: auto;
  align-self: flex-start;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 150px;
  }
}

.memberName {
  composes: h2 from global;
  font-family: Noto Sans JP;
  width: 100%;
  text-align: center;
  color: var(--successColor);
}

.memberInfo p {
  text-align: left;
  margin-bottom: 50px;
  overflow-wrap: break-word;

  @media (max-width: 900px) {
    display: none;
  }
}

.memberFrameOrange {
  display: flex;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #eb7242;
  position: relative;
  overflow: hidden;
  align-items: center;
  align-content: center;

  @media (--viewportMedium) {
    overflow: hidden;
  }

  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
}

.memberFrameOrange:hover {
  /* overflow: visible; */
  border-radius: 70%;
  -moz-border-radius: 70%;
  -webkit-border-radius: 70%;
}

.memberFrameBlue {
  display: flex;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #2f7881;
  position: relative;
  overflow: hidden;
  align-items: center;
  align-content: center;

  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }

  @media (--viewportMedium) {
    overflow: hidden;
  }
}

.memberFrameBlue:hover {
  /*  overflow: visible; */
  border-radius: 70%;
  -moz-border-radius: 70%;
  -webkit-border-radius: 70%;
}

.memberModal {
  display: flex;
  align-self: center;
  width: 260px;
  min-height: 150px;
  max-height: 360px;
  border-radius: 2rem;
  border-style: solid;
  border-width: 0px;
  /* border-color: #fd0000; */
  /*   color: #ff2478; */
  background-color: rgb(255, 255, 255);
  opacity: 0.9;
  z-index: 11;
  cursor: pointer;
  position: fixed;
  top: 40px;
  left: 52px;
  right: 0;
  bottom: 0;
  margin-top: 70px;

  /* centering the modal*/

  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 10px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 16px) calc(100% - 10px) no-repeat,
    linear-gradient(90deg, #ffffff 0%, #1c7881 100%);
  border-radius: 10px;
  padding: 9px;
  box-sizing: border-box;

  @media screen and (min-width: 550px) and (max-width: 1000px) {
    width: 360px;
    height: 550px;
    top: 250px;
    left: 260px;
    right: 0;
    bottom: 0;

    /* centering the modal */

    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 600px) {
    height: 660px;
  }
}

.modalBackdrop {
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(4px);
}

.memberInf {
  top: 0px;
  display: inline-flex;
  position: absolute;
  top: 40px;
  color: black;
  font-weight: bold;
  left: 20px;
  right: 15px;
  margin-bottom: 5px;
}

/*centering span with dynamic text member info*/
.memberInf span {
  margin: 1em 0.5em;
  text-align: justify;
  font-size: 0.9rem;
}

.btnClose {
  margin-left: 210px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 22px;
  height: 25px;
  color: black;
  border: none;

  @media screen and (min-width: 550px) and (max-width: 1000px) {
    margin-left: 300px;
  }
}

.photo {
  position: absolute;
  animation: round 8s infinite;
  opacity: 0;
  width: 100%;
  height: auto;
}

@keyframes round {
  55% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }
}

img:nth-child(1) {
  animation-delay: 4s;
}

img:nth-child(2) {
  animation-delay: 0s;
}

.jasperName,
.gregName {
  animation: round 8s infinite;
  opacity: 0;
}

.jasperNameAnimation {
  animation-delay: 4s;
}

.gregNameAnimation {
  animation-delay: 0s;
}

.dogInfo {
  width: 360px;
  height: auto;
  align-self: flex-start;
  padding-bottom: 50px;

  @media (max-width: 900px) {
    width: 150px;
  }
}

.dogInfo p {
  text-align: left;
  margin-bottom: 50px;
  overflow-wrap: break-word;
  margin-top: 90px;

  @media (max-width: 900px) {
    display: none;
  }
}

.dogInfo h1 {
  @apply --marketplaceH1FontStyles;
  width: 100%;
  text-align: center;
  color: var(--successColor);
  position: absolute;
  padding-bottom: 50px;
}
